import { render, staticRenderFns } from "./PPT.vue?vue&type=template&id=5b81a177&scoped=true&"
import script from "./PPT.vue?vue&type=script&lang=js&"
export * from "./PPT.vue?vue&type=script&lang=js&"
import style0 from "./PPT.vue?vue&type=style&index=0&id=5b81a177&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b81a177",
  null
  
)

export default component.exports